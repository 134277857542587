import { eventBus } from "./EventBus";
export var sendSearchEvent = function (searchEventParams) {
    if (!searchEventParams.currentHref) {
        searchEventParams.currentHref = window.location.href;
    }
    return eventBus.dispatch({
        name: "search",
        params: searchEventParams,
    });
};
export var sendClickEvent = function (clickEventParams) {
    if (!clickEventParams.currentHref) {
        clickEventParams.currentHref = window.location.href;
    }
    eventBus.dispatch({
        name: "click",
        params: clickEventParams,
    });
};
export var sendLoginEvent = function (loginEventParams) {
    return eventBus.dispatch({
        name: "login",
        params: loginEventParams,
    });
};
export var sendExperienceViewedEvent = function (experienceViewedEventParams) {
    return eventBus.dispatch({
        name: "experience_viewed",
        params: experienceViewedEventParams,
    });
};
