import { ReactNode } from 'react';

import IconType from './IconType';

const ROTATION = {
  right: 0,
  down: 90,
  left: 180,
  up: 270,
};

interface SVGIconProps extends IconType {
  children: ReactNode;
}

function SVGIcon({
  size = 24,
  viewBox = '0 0 24 24',
  description,
  children,
  direction,
  rotationOffset = -90,
  ...rest
}: SVGIconProps) {
  return (
    <svg
      width={size}
      height={size}
      aria-hidden="true"
      viewBox={viewBox}
      role={description ? 'img' : 'none'}
      aria-label={description}
      {...rest}
    >
      {description && <title>{description}</title>}
      <g
        transform={
          direction
            ? `rotate(${ROTATION[direction] - rotationOffset})`
            : undefined
        }
        style={{ transformOrigin: 'center' }}
      >
        {children}
      </g>
    </svg>
  );
}

export default SVGIcon;
