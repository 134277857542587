import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

import { CurrencyList } from 'common-toolbox/src/types/currency/index';

function CurrencySetterFromUrl({ actions }: any) {
  const searchParams = useSearchParams();

  useEffect(() => {
    const currencyFromParam = CurrencyList.find(
      (currency) => currency.code === searchParams?.get('currency'),
    );
    if (currencyFromParam) {
      actions.setCurrency(currencyFromParam.code);
      return;
    }
  }, [actions, searchParams]);

  return null;
}

export default CurrencySetterFromUrl;
