export type Currency = {
  code: string;
  name: string;
  symbol: string;
  symbol_native: string;
  countryCode?: string;
  popular?: boolean;
};

export const EUR_CURRENCY: Currency = {
  code: 'EUR',
  name: 'Euro',
  symbol: '€',
  symbol_native: '€',
  popular: true,
};

export const CurrencyList: Currency[] = [
  EUR_CURRENCY,
  {
    code: 'USD',
    name: 'US Dollar',
    symbol: '$',
    symbol_native: '$',
    countryCode: 'US',
    popular: true,
  },
  {
    code: 'GBP',
    name: 'British Pound Sterling',
    symbol: '£',
    symbol_native: '£',
    countryCode: 'GB',
    popular: true,
  },
  {
    code: 'CAD',
    name: 'Canadian Dollar',
    symbol: 'CA$',
    symbol_native: '$',
    countryCode: 'CA',
    popular: true,
  },
  {
    code: 'AED',
    name: 'United Arab Emirates Dirham',
    symbol: 'AED',
    symbol_native: 'د.إ.‏',
    countryCode: 'UE',
    popular: true,
  },
  {
    code: 'AFN',
    name: 'Afghan Afghani',
    symbol: 'Af',
    symbol_native: '؋',
  },
  {
    code: 'ALL',
    name: 'Albanian Lek',
    symbol: 'ALL',
    symbol_native: 'Lek',
  },
  {
    code: 'AMD',
    name: 'Armenian Dram',
    symbol: 'AMD',
    symbol_native: 'դր.',
  },
  {
    code: 'ARS',
    name: 'Argentine Peso',
    symbol: 'AR$',
    symbol_native: '$',
    countryCode: 'UR',
  },
  {
    code: 'AUD',
    name: 'Australian Dollar',
    symbol: 'AU$',
    symbol_native: '$',
    countryCode: 'AU',
    popular: true,
  },
  {
    code: 'AZN',
    name: 'Azerbaijani Manat',
    symbol: 'man.',
    symbol_native: 'ман.',
  },
  {
    code: 'BAM',
    name: 'Bosnia-Herzegovina Convertible Mark',
    symbol: 'KM',
    symbol_native: 'KM',
  },
  {
    code: 'BDT',
    name: 'Bangladeshi Taka',
    symbol: 'Tk',
    symbol_native: '৳',
  },
  {
    code: 'BGN',
    name: 'Bulgarian Lev',
    symbol: 'BGN',
    symbol_native: 'лв.',
    popular: true,
  },
  {
    code: 'BHD',
    name: 'Bahraini Dinar',
    symbol: 'BD',
    symbol_native: 'د.ب.‏',
  },
  {
    code: 'BIF',
    name: 'Burundian Franc',
    symbol: 'FBu',
    symbol_native: 'FBu',
  },
  {
    code: 'BND',
    name: 'Brunei Dollar',
    symbol: 'BN$',
    symbol_native: '$',
  },
  {
    code: 'BOB',
    name: 'Bolivian Boliviano',
    symbol: 'Bs',
    symbol_native: 'Bs',
  },
  {
    code: 'BRL',
    name: 'Brazilian Real',
    symbol: 'R$',
    symbol_native: 'R$',
    countryCode: 'BR',
    popular: true,
  },
  {
    code: 'BWP',
    name: 'Botswanan Pula',
    symbol: 'BWP',
    symbol_native: 'P',
  },
  {
    code: 'BYN',
    name: 'Belarusian Ruble',
    symbol: 'Br',
    symbol_native: 'руб.',
  },
  {
    code: 'BZD',
    name: 'Belize Dollar',
    symbol: 'BZ$',
    symbol_native: '$',
  },
  {
    code: 'CDF',
    name: 'Congolese Franc',
    symbol: 'CDF',
    symbol_native: 'FrCD',
  },
  {
    code: 'CHF',
    name: 'Swiss Franc',
    symbol: 'CHF',
    symbol_native: 'CHF',
    countryCode: 'CH',
    popular: true,
  },
  {
    code: 'CLP',
    name: 'Chilean Peso',
    symbol: 'CL$',
    symbol_native: '$',
    countryCode: 'CL',
    popular: true,
  },
  {
    code: 'CNY',
    name: 'Chinese Yuan',
    symbol: 'CN¥',
    symbol_native: 'CN¥',
    countryCode: 'CN',
    popular: true,
  },
  {
    code: 'COP',
    name: 'Colombian Peso',
    symbol: 'CO$',
    symbol_native: '$',
    countryCode: 'CO',
    popular: true,
  },
  {
    code: 'CRC',
    name: 'Costa Rican Colón',
    symbol: '₡',
    symbol_native: '₡',
    countryCode: 'CR',
    popular: true,
  },
  {
    code: 'CVE',
    name: 'Cape Verdean Escudo',
    symbol: 'CV$',
    symbol_native: 'CV$',
  },
  {
    code: 'CZK',
    name: 'Czech Republic Koruna',
    symbol: 'Kč',
    symbol_native: 'Kč',
    countryCode: 'CZ',
    popular: true,
  },
  {
    code: 'DJF',
    name: 'Djiboutian Franc',
    symbol: 'Fdj',
    symbol_native: 'Fdj',
  },
  {
    code: 'DKK',
    name: 'Danish Krone',
    symbol: 'Dkr',
    symbol_native: 'kr',
    countryCode: 'DK',
    popular: true,
  },
  {
    code: 'DOP',
    name: 'Dominican Peso',
    symbol: 'RD$',
    symbol_native: 'RD$',
  },
  {
    code: 'DZD',
    name: 'Algerian Dinar',
    symbol: 'DA',
    symbol_native: 'د.ج.‏',
  },
  {
    code: 'EGP',
    name: 'Egyptian Pound',
    symbol: 'EGP',
    symbol_native: 'ج.م.‏',
  },
  {
    code: 'ERN',
    name: 'Eritrean Nakfa',
    symbol: 'Nfk',
    symbol_native: 'Nfk',
  },
  {
    code: 'ETB',
    name: 'Ethiopian Birr',
    symbol: 'Br',
    symbol_native: 'Br',
  },
  {
    code: 'GEL',
    name: 'Georgian Lari',
    symbol: 'GEL',
    symbol_native: 'GEL',
  },
  {
    code: 'GHS',
    name: 'Ghanaian Cedi',
    symbol: 'GH₵',
    symbol_native: 'GH₵',
  },
  {
    code: 'GNF',
    name: 'Guinean Franc',
    symbol: 'FG',
    symbol_native: 'FG',
  },
  {
    code: 'GTQ',
    name: 'Guatemalan Quetzal',
    symbol: 'GTQ',
    symbol_native: 'Q',
  },
  {
    code: 'HKD',
    name: 'Hong Kong Dollar',
    symbol: 'HK$',
    symbol_native: '$',
    countryCode: 'HK',
    popular: true,
  },
  {
    code: 'HNL',
    name: 'Honduran Lempira',
    symbol: 'HNL',
    symbol_native: 'L',
  },
  {
    code: 'HUF',
    name: 'Hungarian Forint',
    symbol: 'Ft',
    symbol_native: 'Ft',
    countryCode: 'HU',
    popular: true,
  },
  {
    code: 'IDR',
    name: 'Indonesian Rupiah',
    symbol: 'Rp',
    symbol_native: 'Rp',
  },
  {
    code: 'ILS',
    name: 'Israeli New Sheqel',
    symbol: '₪',
    symbol_native: '₪',
    countryCode: 'IL',
    popular: true,
  },
  {
    code: 'INR',
    name: 'Indian Rupee',
    symbol: 'Rs',
    symbol_native: '₹',
    countryCode: 'IN',
  },
  {
    code: 'IQD',
    name: 'Iraqi Dinar',
    symbol: 'IQD',
    symbol_native: 'د.ع.‏',
  },
  {
    code: 'IRR',
    name: 'Iranian Rial',
    symbol: 'IRR',
    symbol_native: '﷼',
  },
  {
    code: 'ISK',
    name: 'Icelandic Króna',
    symbol: 'Ikr',
    symbol_native: 'kr',
    countryCode: 'IS',
  },
  {
    code: 'JMD',
    name: 'Jamaican Dollar',
    symbol: 'J$',
    symbol_native: '$',
  },
  {
    code: 'JOD',
    name: 'Jordanian Dinar',
    symbol: 'JD',
    symbol_native: 'د.أ.‏',
  },
  {
    code: 'JPY',
    name: 'Japanese Yen',
    symbol: '¥',
    symbol_native: '￥',
    countryCode: 'JP',
    popular: true,
  },
  {
    code: 'KES',
    name: 'Kenyan Shilling',
    symbol: 'Ksh',
    symbol_native: 'Ksh',
  },
  {
    code: 'KHR',
    name: 'Cambodian Riel',
    symbol: 'KHR',
    symbol_native: '៛',
  },
  {
    code: 'KMF',
    name: 'Comorian Franc',
    symbol: 'CF',
    symbol_native: 'FC',
  },
  {
    code: 'KRW',
    name: 'South Korean Won',
    symbol: '₩',
    symbol_native: '₩',
    popular: true,
  },
  {
    code: 'KWD',
    name: 'Kuwaiti Dinar',
    symbol: 'KD',
    symbol_native: 'د.ك.‏',
  },
  {
    code: 'KZT',
    name: 'Kazakhstani Tenge',
    symbol: 'KZT',
    symbol_native: 'тңг.',
  },
  {
    code: 'LBP',
    name: 'Lebanese Pound',
    symbol: 'LB£',
    symbol_native: 'ل.ل.‏',
  },
  {
    code: 'LKR',
    name: 'Sri Lankan Rupee',
    symbol: 'SLRs',
    symbol_native: 'SL Re',
  },
  {
    code: 'LYD',
    name: 'Libyan Dinar',
    symbol: 'LD',
    symbol_native: 'د.ل.‏',
  },
  {
    code: 'MAD',
    name: 'Moroccan Dirham',
    symbol: 'MAD',
    symbol_native: 'د.م.‏',
    popular: true,
  },
  {
    code: 'MDL',
    name: 'Moldovan Leu',
    symbol: 'MDL',
    symbol_native: 'MDL',
  },
  {
    code: 'MGA',
    name: 'Malagasy Ariary',
    symbol: 'MGA',
    symbol_native: 'MGA',
  },
  {
    code: 'MKD',
    name: 'Macedonian Denar',
    symbol: 'MKD',
    symbol_native: 'MKD',
  },
  {
    code: 'MMK',
    name: 'Myanma Kyat',
    symbol: 'MMK',
    symbol_native: 'K',
  },
  {
    code: 'MOP',
    name: 'Macanese Pataca',
    symbol: 'MOP$',
    symbol_native: 'MOP$',
  },
  {
    code: 'MUR',
    name: 'Mauritian Rupee',
    symbol: 'MURs',
    symbol_native: 'MURs',
  },
  {
    code: 'MXN',
    name: 'Mexican Peso',
    symbol: 'MX$',
    symbol_native: '$',
    countryCode: 'MX',
    popular: true,
  },
  {
    code: 'MYR',
    name: 'Malaysian Ringgit',
    symbol: 'RM',
    symbol_native: 'RM',
    popular: true,
  },
  {
    code: 'MZN',
    name: 'Mozambican Metical',
    symbol: 'MTn',
    symbol_native: 'MTn',
  },
  {
    code: 'NAD',
    name: 'Namibian Dollar',
    symbol: 'N$',
    symbol_native: 'N$',
  },
  {
    code: 'NGN',
    name: 'Nigerian Naira',
    symbol: '₦',
    symbol_native: '₦',
  },
  {
    code: 'NIO',
    name: 'Nicaraguan Córdoba',
    symbol: 'C$',
    symbol_native: 'C$',
  },
  {
    code: 'NOK',
    name: 'Norwegian Krone',
    symbol: 'Nkr',
    symbol_native: 'kr',
    countryCode: 'NO',
    popular: true,
  },
  {
    code: 'NPR',
    name: 'Nepalese Rupee',
    symbol: 'NPRs',
    symbol_native: 'नेरू',
  },
  {
    code: 'NZD',
    name: 'New Zealand Dollar',
    symbol: 'NZ$',
    symbol_native: '$',
    countryCode: 'NZ',
    popular: true,
  },
  {
    code: 'OMR',
    name: 'Omani Rial',
    symbol: 'OMR',
    symbol_native: 'ر.ع.‏',
  },
  {
    code: 'PAB',
    name: 'Panamanian Balboa',
    symbol: 'B/.',
    symbol_native: 'B/.',
  },
  {
    code: 'PEN',
    name: 'Peruvian Nuevo Sol',
    symbol: 'S/.',
    symbol_native: 'S/.',
    popular: true,
  },
  {
    code: 'PHP',
    name: 'Philippine Peso',
    symbol: '₱',
    symbol_native: '₱',
    popular: true,
  },
  {
    code: 'PKR',
    name: 'Pakistani Rupee',
    symbol: 'PKRs',
    symbol_native: '₨',
  },
  {
    code: 'PLN',
    name: 'Polish Zloty',
    symbol: 'zł',
    symbol_native: 'zł',
    countryCode: 'PL',
    popular: true,
  },
  {
    code: 'PYG',
    name: 'Paraguayan Guarani',
    symbol: '₲',
    symbol_native: '₲',
  },
  {
    code: 'QAR',
    name: 'Qatari Rial',
    symbol: 'QR',
    symbol_native: 'ر.ق.‏',
  },
  {
    code: 'RON',
    name: 'Romanian Leu',
    symbol: 'RON',
    symbol_native: 'RON',
    popular: true,
  },
  {
    code: 'RSD',
    name: 'Serbian Dinar',
    symbol: 'din.',
    symbol_native: 'дин.',
  },
  {
    code: 'RUB',
    name: 'Russian Ruble',
    symbol: 'RUB',
    symbol_native: '₽.',
    countryCode: 'RU',
  },
  {
    code: 'RWF',
    name: 'Rwandan Franc',
    symbol: 'RWF',
    symbol_native: 'FR',
  },
  {
    code: 'SAR',
    name: 'Saudi Riyal',
    symbol: 'SR',
    symbol_native: 'ر.س.‏',
    popular: true,
  },
  {
    code: 'SDG',
    name: 'Sudanese Pound',
    symbol: 'SDG',
    symbol_native: 'SDG',
  },
  {
    code: 'SEK',
    name: 'Swedish Krona',
    symbol: 'Skr',
    symbol_native: 'kr',
    countryCode: 'SE',
    popular: true,
  },
  {
    code: 'SGD',
    name: 'Singapore Dollar',
    symbol: 'S$',
    symbol_native: '$',
    countryCode: 'SG',
    popular: true,
  },
  {
    code: 'SOS',
    name: 'Somali Shilling',
    symbol: 'Ssh',
    symbol_native: 'Ssh',
  },
  {
    code: 'SYP',
    name: 'Syrian Pound',
    symbol: 'SY£',
    symbol_native: 'ل.س.‏',
  },
  {
    code: 'THB',
    name: 'Thai Baht',
    symbol: '฿',
    symbol_native: '฿',
    countryCode: 'TH',
    popular: true,
  },
  {
    code: 'TND',
    name: 'Tunisian Dinar',
    symbol: 'DT',
    symbol_native: 'د.ت.‏',
  },
  {
    code: 'TOP',
    name: 'Tongan Paʻanga',
    symbol: 'T$',
    symbol_native: 'T$',
  },
  {
    code: 'TRY',
    name: 'Turkish Lira',
    symbol: 'TL',
    symbol_native: 'TL',
    countryCode: 'TR',
    popular: true,
  },
  {
    code: 'TTD',
    name: 'Trinidad and Tobago Dollar',
    symbol: 'TT$',
    symbol_native: '$',
  },
  {
    code: 'TWD',
    name: 'New Taiwan Dollar',
    symbol: 'NT$',
    symbol_native: 'NT$',
    popular: true,
  },
  {
    code: 'TZS',
    name: 'Tanzanian Shilling',
    symbol: 'TSh',
    symbol_native: 'TSh',
  },
  {
    code: 'UAH',
    name: 'Ukrainian Hryvnia',
    symbol: '₴',
    symbol_native: '₴',
  },
  {
    code: 'UGX',
    name: 'Ugandan Shilling',
    symbol: 'USh',
    symbol_native: 'USh',
  },
  {
    code: 'UYU',
    name: 'Uruguayan Peso',
    symbol: '$U',
    symbol_native: '$',
  },
  {
    code: 'UZS',
    name: 'Uzbekistan Som',
    symbol: 'UZS',
    symbol_native: 'UZS',
  },
  {
    code: 'VEF',
    name: 'Venezuelan Bolívar',
    symbol: 'Bs.F.',
    symbol_native: 'Bs.F.',
  },
  {
    code: 'VND',
    name: 'Vietnamese Dong',
    symbol: '₫',
    symbol_native: '₫',
  },
  {
    code: 'XAF',
    name: 'CFA Franc BEAC',
    symbol: 'FCFA',
    symbol_native: 'FCFA',
  },
  {
    code: 'XOF',
    name: 'CFA Franc BCEAO',
    symbol: 'CFA',
    symbol_native: 'CFA',
  },
  {
    code: 'YER',
    name: 'Yemeni Rial',
    symbol: 'YR',
    symbol_native: 'ر.ي.‏',
  },
  {
    code: 'ZAR',
    name: 'South African Rand',
    symbol: 'R',
    symbol_native: 'R',
    countryCode: 'ZA',
    popular: true,
  },
  {
    code: 'ZWL',
    name: 'Zimbabwean Dollar',
    symbol: 'ZWL$',
    symbol_native: 'ZWL$',
  },
];
