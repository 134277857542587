import cookies from 'js-cookie';
import lscache from 'lscache';

import { CurrencyList, EUR_CURRENCY } from 'common-toolbox/src/types/currency';

const MONTH = 60 * 24 * 30;

export interface ActionFactory {
  setCurrency: (currencyCode: string) => void;
}

function actionsFactory(dispatcher: any): ActionFactory {
  return {
    setCurrency(newCurrencyCode) {
      const newCurrency =
        CurrencyList.find((currency) => currency.code === newCurrencyCode) ??
        EUR_CURRENCY;
      dispatcher({ type: 'SET_CURRENCY', payload: { currency: newCurrency } });
      // store just the currency code in the cookie and in localStorage
      lscache.set('CURRENCY', newCurrency.code, MONTH);
      cookies.set('CURRENCY', newCurrency.code, { expires: MONTH });
    },
  };
}

export default actionsFactory;
