'use client';

import lscache from 'lscache';
import {
  ReactNode,
  Suspense,
  createContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import {
  Currency,
  CurrencyList,
  EUR_CURRENCY,
} from 'common-toolbox/src/types/currency';

import CurrencySetterFromUrl from './CurrencySetterFromUrl';
import actionsFactory from './actionsFactory';

export type CurrencyState = {
  currency?: Currency;
};

export type CurrencyAction = {
  type: 'SET_CURRENCY' | 'SET_RATES';
  payload: any;
};

const initState: CurrencyState = {
  currency: EUR_CURRENCY,
};

function reducer(state: any, action: CurrencyAction) {
  switch (action.type) {
    case 'SET_CURRENCY':
      return {
        ...state,
        currency: {
          ...action.payload.currency,
        },
      };
  }
  return state;
}

const initialContext = {
  state: initState,
  actions: actionsFactory(() => {}),
};

export const CurrencyContext = createContext(initialContext);

function CurrencyProvider({
  children,
  initialState = initState,
}: {
  children: ReactNode;
  initialState?: CurrencyState;
}) {
  const [state, dispatcher] = useReducer(reducer, initialState);
  const actions = useMemo(() => actionsFactory(dispatcher), [dispatcher]);

  useEffect(() => {
    const currencyFromCache = CurrencyList.find(
      (currency) => currency.code === lscache.get('CURRENCY'),
    );
    if (currencyFromCache) {
      actions.setCurrency(currencyFromCache.code);
      return;
    }
  }, [actions]);

  const contextValue = { state, actions };

  return (
    <CurrencyContext.Provider value={contextValue}>
      <Suspense>
        <CurrencySetterFromUrl actions={actions} />
      </Suspense>
      {children}
    </CurrencyContext.Provider>
  );
}

export default CurrencyProvider;
