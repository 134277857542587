import IconType from '../../IconType';
import SVGIcon from '../../SVGIcon';
import CSS from './ChevronIcon.module.scss';

type ChevronIconProps = {
  direction?: 'right' | 'left' | 'up' | 'down';
  variant?: 'black' | 'pink' | 'purple' | 'dark-green' | 'gray' | 'white';
} & IconType;

function ChevronIcon({
  direction = 'right',
  variant,
  ...props
}: ChevronIconProps) {
  return (
    <SVGIcon
      {...props}
      direction={direction}
      size={props.size ?? 24}
      viewBox="0 0 24 24"
      rotationOffset={0}
    >
      <path
        d="M7.84101107,5.36100764 C8.60813145,4.56627492 9.87426356,4.54389069 10.6689963,5.31101107 L16.1054741,10.5586028 C16.9200185,11.3448466 16.9201681,12.6498356 16.1058039,13.4362661 L10.6693262,18.6862661 C9.87476933,19.4535687 8.60863212,19.4314748 7.84132956,18.6369179 C7.07402699,17.8423611 7.09612094,16.5762239 7.89067777,15.8089213 L11.837002,11.997002 L7.89100764,8.18899285 C7.13810296,7.46224723 7.07838639,6.28763512 7.72644528,5.49026772 L7.84101107,5.36100764 Z"
        data-variant={variant}
        className={CSS.Chevron}
      />
    </SVGIcon>
  );
}

export default ChevronIcon;
